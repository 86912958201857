import React from "react"

import SEO from "../components/seo"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <h1>404: No se encontró</h1>
    <p>Entraste a una página que no existe.</p>
  </>
)

export default NotFoundPage
